"use client";

import { Button } from "react-aria-components";
import { useIntl } from "react-intl";

export const GlobalErrorPage = () => {
  const intl = useIntl();

  return (
    <>
      <title>
        {intl.formatMessage({ defaultMessage: "Ошибка", id: "6DyGho" })}
      </title>
      <div className="container grid h-dvh py-4">
        <div className="flex flex-col items-center gap-4 place-self-center">
          <h1 className="text-body-large">
            {intl.formatMessage({
              defaultMessage: "Что-то пошло не так!",
              id: "oLwTAo",
            })}
          </h1>
          <Button
            className="button button_filled"
            onPress={() => {
              window.location.reload();
            }}
          >
            {intl.formatMessage({
              defaultMessage: "Перезагрузить страницу",
              id: "klTjzd",
            })}
          </Button>
        </div>
      </div>
    </>
  );
};
